import React from 'react'
import { Button, TextInput } from '@ridecell/web-components-material'
import { Typography, Grid, Box, Container } from '@material-ui/core'

function Sandbox() {

  const [formData, setFormData] = React.useState({
    fname: '',
    lname: '',
    company: '',
    email: '',
    comment: '',
    formname: ''
  })

  const [formSubmitted, setFormSubmitted] = React.useState(false)

  const handleFormSubmit = () => {
    fetch('http://info.ridecell.com/l/548212/2022-01-03/g2t7z1', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
      .then((response) => {
        response.json()
        if (response.status >= 500) {
          console.log('fails!')
        } else {
          setFormSubmitted(true)
          console.log('success!')
        }
        console.log(response)
      })
      .then((json) => console.log(json));
  }

  const handleChange = (event) => {
    const name = event.target.name
    setFormData({
      ...formData,
      [name]: event.target.value
    })
  }

  return (
    <Container maxWidth='sm'>
      {formSubmitted ?
        // display success message
        <Box mt={4} mb={4}>
          <Typography variant='h6'>Thank you for submitting your information!</Typography>
          <Typography variant='p' color='textSecondary'>Watch for an email from us.</Typography>
        </Box>
        :
        <>
          <Box mt={4} mb={4}>
            <Typography variant='h3' component='h1'>Request access</Typography>
            <Typography variant='h6' component='p' paragraph color='textSecondary'>Please provide us with the information below and we will send you access to our Sandbox.</Typography>
          </Box>
          <form action='http://info.ridecell.com/l/548212/2022-01-03/g2t7z1' method='post'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput placeholder='First Name' name='fname' onChange={handleChange} variant='outlined' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput placeholder='Last Name' name='lname' onChange={handleChange} variant='outlined' fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder='Company' name='company' onChange={handleChange} variant='outlined' fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder='Email' name='email' onChange={handleChange} variant='outlined' fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput placeholder='Message' name='comment' onChange={handleChange} variant='outlined' multiline fullWidth />
              </Grid>
              <input type='hidden' name='formname' onChange={handleChange} value='request-sandbox' />
              <Grid item xs={12}>
                <Button size='large' type='submit'>Request access</Button>
              </Grid>
            </Grid>
          </form>
        </>
      }
    </Container>
  )
}

export default Sandbox