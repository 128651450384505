import React from 'react'
import './App.css'
import Home from './Home'
import Sandbox from './Sandbox'
// router
import { Routes, Route, Outlet, Link as RouterLink } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
// logo
import { ReactComponent as Logo } from './assets/nemo_logo.svg'
// material components
import { AppBar, Box, Hidden, Toolbar, Link as MuiLink, Link } from '@material-ui/core'
// ridecell components
import { ThemeProvider, Button } from '@ridecell/web-components-material'
// styling
import { withStyles } from '@material-ui/core/styles';


const NavBar = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))(AppBar);

const NavLink = withStyles(theme => ({
  root: {
    marginRight: theme.spacing(2)
  }
}))(MuiLink);

function App() {

  return (
    <ThemeProvider>
      <NavBar position='sticky'>
        <Toolbar variant='dense'>
          <Box flexGrow={1} display="flex">
            <RouterLink to='/'><Logo /></RouterLink>
          </Box>
          <Hidden smDown>
            <Box display="flex" alignItems="center">
              <Box mr={7}>
                <NavLink variant='body1' component={HashLink} to='/#search-and-analytics'>Benefits</NavLink>
                {/* <NavLink variant='body1' href='#search-and-analytics'>Benefits</NavLink> */}
                <NavLink variant='body1' component={HashLink} to='/#tagged-vehicles'>Technology</NavLink>
                <NavLink variant='body1' href='https://nemo-blog.medium.com/'>Blog</NavLink>
                <NavLink variant='body1' component={HashLink} to='/#contact-sales'>Contact us</NavLink>
              </Box>
              <Button variant='outlined' component={HashLink} to='/Sandbox'>Open Sandbox</Button>
            </Box>
          </Hidden>
        </Toolbar>
      </NavBar>
      <Routes>
        <Route index element={<Home />} />
        <Route path='/Sandbox' element={<Sandbox />} />
      </Routes>
    </ThemeProvider >
  );
}

export default App;
