import React from 'react'
// material components
import { Avatar, Box, Container, Divider, Grid, Hidden, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@material-ui/core'
import { Link } from "react-router-dom"
// ridecell components
import { Button } from '@ridecell/web-components-material'
// styling
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Check } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  objects: {
    color: '#000',
    backgroundColor: '#F2A23B',
    marginBottom: theme.spacing(2)
  },
  road: {
    color: '#fff',
    backgroundColor: '#AD0CCE',
    marginBottom: theme.spacing(2)
  },
  traffic: {
    color: '#fff',
    backgroundColor: '#5DB8E1',
    marginBottom: theme.spacing(2)
  },
  vehicle: {
    color: '#fff',
    backgroundColor: '#EA3122',
    marginBottom: theme.spacing(2)
  },
  environment: {
    color: '#fff',
    backgroundColor: '#8BD640',
    marginBottom: theme.spacing(2)
  },
  checkBullet: {
    backgroundColor: '#fff',
    color: '#5DB8E1',
  },
  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#fff'
    },
    color: '#fff'
  }
}));

const LightOutlinedButton = withStyles(theme => ({
  root: {
    borderColor: '#fff',
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.dark}`
    }
  },
  label: {
    color: '#fff'
  }
}))(Button);

const inputProps = {
  placeholder: {
    color: 'red'
  }
};

const TextFieldFooter = withStyles(theme => ({
  root: {
    backgroundColor: '#424242',
    borderWidth: 0,
    borderRadius: 4,
    color: '#fff'
  }
}))(TextField);

const TitleDivider = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  middle: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))(Divider);

function Home() {
  const mdScreen = useMediaQuery('(max-width:960px)')
  const classes = useStyles();

  const [formData, setFormData] = React.useState({
    fname: '',
    lname: '',
    company: '',
    email: '',
    comment: '',
    formname: ''
  })

  const [formSubmitted, setFormSubmitted] = React.useState(false)

  const handleFormSubmit = () => {
    fetch('https://jsonplaceholder.typicode.com/posts', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        response.json()
        if (response.status >= 500) {
          console.log('fails!')
        } else {
          setFormSubmitted(true)
          console.log('success!')
        }
        console.log(response)
      })
      .then((json) => console.log(json));
  }

  const handleChange = (event) => {
    const name = event.target.name
    setFormData({
      ...formData,
      [name]: event.target.value
    })
  }

  return (
    <>
      <section className='Hero'>
        <Container>
          <Grid container>
            <Grid item md={9}>
              <h1 className='HeroTopHeading'>Navigate the<br /> expanding</h1>
              <h1 className='HeroTagline'>oceans of data.</h1>
              <Box display="flex">
                <Box marginRight={1}>
                  <Button size='large' component={Link} to='/Sandbox' >Open Sandbox</Button>
                </Box>
                <Box>
                  <LightOutlinedButton size='large' variant='outlined' href='#contact-sales'>Talk to sales</LightOutlinedButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='SearchAndAnalytics' id='search-and-analytics'>
        <Container>
          <Grid container>
            <Grid item md={6}>
              <Typography variant='h4' gutterBottom>Search and Analytics Platform for Automated Driving Sensor Data</Typography>
              <Typography variant='body1' color='textSecondary' paragraph>Companies working on L2+, L3, and L4 automated vehicles drive thousands of miles collecting Petabytes of data. But only 5-10% of the data collected is useful for further R&D. The question is —which 5-10%? Nemo finds valuable events and traffic scenarios from driving logs.</Typography>
              <Button variant='outlined'>Learn more</Button>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='DataExplosion'>
        <Container>
          <Typography variant='h4' gutterBottom>Data explosion is happening. Companies need automated tools to process the raw data.</Typography>
        </Container>
      </section>
      <section className='Scenario'>
        <Container>
          <Typography variant='h5' color='textSecondary' gutterBottom>Give me...</Typography>
          <Typography variant='h4' gutterBottom>"all scenarios where a truck made an aggressive cut-in at a highway exit."</Typography>
          <TitleDivider variant='middle' light className='ScenarioDivider' />
          <Typography variant='body1' color='textSecondary' paragraph>Nemo finds events and traffic scenarios that match your search query from all of your data.<br /> Think Google Search for your sensor data.</Typography>
        </Container>
      </section>
      <section className='TaggedVehicles' id='tagged-vehicles'>
        <Container>
          <Grid container>
            <Hidden smDown>
              <Grid item md={6} className='TaggedVehiclesImage'>
              </Grid>
            </Hidden>
            <Grid item md={6} className='TaggedVehiclesText'>
              <Box padding={mdScreen ? 0 : 6}>
                <Typography variant='h4' gutterBottom>Powered by scenario extraction engine at its core</Typography>
                <Typography variant='body1'><strong>Provides developers instant access to the right data</strong></Typography>
                <Typography variant='body1' color='textSecondary' paragraph>
                  Developers can zero-in the right data they need without spending their engineering time manually reviewing the driving logs. The shortlisted scenarios can be exported for algorithm training, product testing, annotation, or simulation.</Typography>
                <Typography variant='body1'><strong>Reduces storage cost by up to 85%</strong></Typography>
                <Typography variant='body1' color='textSecondary' paragraph>
                  Ideally, only frequently accessed data should be kept in hot storage since it’s 10x costlier than cold storage. By automating the process of filtering and tiering the data based on which events and scenarios are useful for R&D teams, companies can save up to 85% in just the storage cost.</Typography>
                <Typography variant='body1'><strong>Provides scenario distribution and coverage analytics</strong></Typography>
                <Typography variant='body1' color='textSecondary' paragraph>
                  Provides analytics such as heat maps, time-series plots, and frequency plots, that offer deeper insights to product development and testing teams in terms of scenario coverage, distribution, and biases in training/testing dataset.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='SceneUnderstanding'>
        <Container>
          <Typography variant='h4' gutterBottom>Contextual scene understanding AI</Typography>
          <Typography variant='body1'>Nemo scans the sensor data (coming from Lidars, camera, radars, and CAN bus) to find relevant metadata across objects in the scene, road infrastructure, traffic interactions between different objects, and the vehicle’s driving behavior.</Typography>
        </Container>
      </section>
      <section className='TagCategories'>
        <Container>
          <Grid container spacing={3}>
            <Hidden smDown>
              <Grid item md={1}></Grid>
            </Hidden>
            <Grid item xs={6} md={2}>
              <Avatar className={classes.objects}>O</Avatar>
              <Typography variant='h6' gutterBottom>Objects<br />in the scene</Typography>
              <Typography variant='body1' color='textSecondary' paragraph>Ego (self)
                Cars<br />
                Pedestrians<br />
                Bicyclist<br />
                Trucks<br />

                Emergency vehicles<br />
                Trailer<br />
                Motorcyclist<br />
                School bus</Typography>

            </Grid>
            <Grid item xs={6} md={2}>
              <Avatar className={classes.road}>R</Avatar>
              <Typography variant='h6' gutterBottom>Road<br />infrastructure</Typography>
              <Typography variant='body1' color='textSecondary' paragraph>Highway
                Urban road<br />
                Flyover<br />
                Bridge<br />

                Intersection<br />
                4-way-stop<br />
                T-junction<br />
                Roundabout<br />
                Crosswalk</Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Avatar className={classes.traffic}>I</Avatar>
              <Typography variant='h6' gutterBottom>Traffic<br />interactions</Typography>
              <Typography variant='body1' color='textSecondary' paragraph>Cut-in
                Cut-out<br />
                Overtaking<br />

                Left/right turn<br />
                U-turn<br />

                On-coming traffic<br />
                Cross traffic<br />
                Parallel/anti-parallel</Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Avatar className={classes.vehicle}>B</Avatar>
              <Typography variant='h6' gutterBottom>Vehicle<br />driving behavior</Typography>
              <Typography variant='body1' color='textSecondary' paragraph>Hard brake
                Sudden acceleration<br />
                Speed value<br />
                Steering rate<br />

                Time to collision<br />
                Distance to front object<br />

                Lane departure<br />
                Rolling stop</Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Avatar className={classes.environment}>E</Avatar>
              <Typography variant='h6' gutterBottom>Environment<br />around vehicle</Typography>
              <Typography variant='body1' color='textSecondary' paragraph>Day
                Night<br />

                Rain<br />
                Snow<br />
                Fog<br />

                City/State<br />
                Geofence coordinates
              </Typography>
            </Grid>
          </Grid>

        </Container>
      </section>
      <section className='MicroServices'>
        <Container>
          <Typography variant='h4' gutterBottom>Micro-services to support different teams within your organization</Typography>
          <Typography variant='body1' color='textSecondary' paragraph>Nemo scans the sensor data (coming from Lidars, camera, radars, and CAN bus) to find relevant metadata across objects in the scene, road infrastructure, traffic interactions between different objects, and the vehicle’s driving behavior.</Typography>
        </Container>
      </section>
      <section className='EasyDeployment'>
        <Container>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant='h4' gutterBottom>Easy deployment within your IT infrastructure</Typography>
            </Grid>
            <Grid item md={6}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.checkBullet}><Check /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Deployable in cloud, on-premise and hybrid environments' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.checkBullet}><Check /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Low compute requirements' />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.checkBullet}><Check /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Full data security and privacy with compliance' />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='Contact' id='contact-sales'>
        <Container>
          {formSubmitted ?
            // display success message
            <Typography variant='h6'>Thank you for submitting your information!</Typography>
            :
            // display form
            <Grid container spacing={3}>
              <Grid item md={6}>
                <form action='http://info.ridecell.com/l/548212/2022-01-03/g2t7z1' method='post'>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextFieldFooter placeholder="First Name" name='fname' onChange={handleChange} variant="outlined" InputProps={{ classes: { input: classes.input } }} fullWidth margin='dense' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldFooter placeholder="Last Name" name='lname' onChange={handleChange} variant="outlined" InputProps={{ classes: { input: classes.input } }} fullWidth margin='dense' />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFooter placeholder="Company" name='company' onChange={handleChange} variant="outlined" InputProps={{ classes: { input: classes.input } }} fullWidth margin='dense' />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFooter placeholder="Email" name='email' onChange={handleChange} variant="outlined" InputProps={{ classes: { input: classes.input } }} fullWidth margin='dense' />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFooter placeholder="Message" name='comment' onChange={handleChange} variant="outlined" InputProps={{ classes: { input: classes.input } }} multiline fullWidth margin='dense' />
                    </Grid>
                    <input type='hidden' name='formname' onChange={handleChange} value='contact-sales' />
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <LightOutlinedButton variant='outlined' type='submit'>Send</LightOutlinedButton>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item md={6}>
                <Box ml={mdScreen ? 0 : 3}>
                  <Typography variant='h4' gutterBottom>Contact us</Typography>
                  <Typography variant='body1' paragraph>Interested in learning more about our product or simply exploring ways to manage your growing data? We would love to help. Drop us a note for a private demo.</Typography>
                </Box>
              </Grid>
            </Grid>
          }
        </Container>
      </section>
    </>
  );
}

export default Home;